import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import SEO from "../components/seo";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import { FaCommentDollar, FaCloud, FaFile } from "react-icons/fa";

const Metodyfinansowania = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Metody finansowania"
      headline="wdrożenia systemu dla agencji reklamowej i marketingowej"
    >
      <SEO
        title="Metody finansowania oprogramowania - Leasing IT"
        description="Chcesz wiedzieć jak sfinansować wdrożenie systemu dla agencji reklamowej i marketingowej? ✅ Wynajem • Zakup • Leasing • Zapytaj o szczegóły."
        keywords={["metody finansowania"]}
      />
      <p>
        <strong>
          Zastanawiasz się nad metodami finansowania wdrożenia programu dla Twojej agencji reklamowej lub marketingowej?
        </strong>
      </p>
      <p>
     Umożliwiamy finansowanie projektów wdrożeniowych na różne sposoby, a każdy z nich ma swoje indywidualne zalety. Warto zapoznać się z plusami każdego rozwiązania, aby wybrać to, które najlepiej odpowiada potrzebom Twojego przedsiębiorstwa.
      </p>
      <br />
      <h4>
        <strong>Dostępne modele finansowania: </strong>
      </h4>
      <h3>Co nas wyróżnia?</h3>
      <BenefitBlockWrapper>
        <BenefitBlock icon={<FaCloud size="60px" />} title="Wynajem" />
        <BenefitBlock icon={<FaCommentDollar size="60px" />} title="Zakup" />
        <BenefitBlock icon={<FaFile size="60px" />} title="Leasing" />
      </BenefitBlockWrapper>
      <br />
      <h3>
        <strong>Czym jest wynajem programu dla agencji reklamowej? </strong>
      </h3>
      <p>
        {" "}
        Wynajem to rozwiązanie, polegające na tym, że utrzymanie systemu na serwerze leży w zakresie obowiązków dostawcy. Po Twojej stronie jest jedynie stała, określona w umowie kwota (raz na miesiąc lub raz na rok), a wszystkie pozostałe kwestie są po stronie dostawcy.
      </p>
      <p>
        <strong>Dlaczego warto?</strong>{" "}
      </p>
      <p>
        <ul>
          <li>
            <strong>Brak kosztów utrzymania</strong> — utrzymanie systemu na bezpiecznych serwerach dostawcy.{" "}
          </li>
          <li>
            <strong>Pomoc i wsparcie ze strony dostawcy</strong> — dostęp do pomocy technicznej oraz merytorycznej (how-to) i bezpłatne standardowe wsparcie.
          </li>
          <li>
            <strong>Bezpieczeństwo</strong> — dostawca zapewnia ochronę bezpieczeństwa danych.
          </li>
          <li>
            <strong>Aktualizacje</strong> — stały bezpłatny dostęp do aktualizacji systemu, zawierających nowe funkcjonalności oraz usprawnienia.
          </li>
          <li>
            <strong>Błyskawiczne wdrożenie</strong> — dzięki wykorzystaniu modelu SaaS, wdrożenie jest szybkie i nie wymaga konfiguracji urządzeń.
          </li>
        </ul>
      </p>
      <br />
      <h3>
        <strong>Czym jest zakup systemu dla agencji reklamowej i marketingowej? </strong>{" "}
      </h3>
      <p>
        {" "}
  W tym modelu otrzymujesz licencję na system, a rozliczasz się jednorazowo.  Potem przechowujesz go na stałe na swoich serwerach. Zakup programu dla firmy wiąże się z jego samodzielnym utrzymaniem i obsługą.
      </p>
      <p>
        <strong>Dlaczego warto?</strong>{" "}
      </p>
      <p>
        <ul>
          <li>
            <strong>Indywidualna licencja</strong> — otrzymujesz dożywotnią licencję na program dla firmy.{" "}
          </li>
          <li>
            <strong>Aktualizacje</strong> — masz roczny dostęp do wielu aktualizacji, zawierających zupełnie nowe funkcjonalności oraz usprawnienia.
          </li>
          <li>
            <strong>Pełna kontrola nad danymi</strong> — sam decydujesz o tym, jak chronisz dane, które przechowujesz w systemie.
          </li>
        </ul>
      </p>
      <br />
      <h3>
        <strong> Czym jest leasing programu? </strong>
      </h3>
      <p>
        {" "}
        Metoda leasingu dotyczy jednorazowego zakupu i wdrożenia programu dla firmy. W ramach tej metody finansowania przekazujemy Ci oprogramowanie wraz usługą wdrożenia. Pozwala ona rozłożyć koszty projektu wdrożeniowego <strong>nawet na 5 lat</strong>.
      </p>
      <p>
        <strong>Dlaczego warto?</strong>{" "}
      </p>
      <p>
        <ul>
          <li>
            <strong>Umożliwia finansowanie całego projektu</strong> — zarówno zakupu licencji jak i usług wdrożeniowych.
          </li>
          <li>
            <strong>Minimum formalności</strong> – liczba wymaganych dokumentów została ograniczona do niezbędnego minimum, więc nie będziesz musiał czekać na domknięcie formalności.
          </li>
          <li>
            <strong>Obniżenie kosztów początkowych</strong> — leasing IT sprawia, że nie ponosisz dużych kosztów na początku projektu.
          </li>
          <li>
            <strong>Współpracujemy z doświadczonym ekspertem w dziedzinie leasingu IT</strong> – Grenke Leasing, zapewniającym Klientom indywidualną obsługę i wsparcie na każdym etapie.
          </li>
          <li>
            <strong>Redukcja kosztów</strong>— raty leasingowe w całości mogą być zaliczone do kosztów uzyskania przychodu. Wpływa to na obniżenie podstawy opodatkowania podatkiem dochodowym.
          </li>
          <li>
            <strong>Ochrona kapitału firmy</strong> – leasing nie obciąża bilansu finansowego firmy i tym samym nie pogarsza jej bieżącej kondycji.
          </li>
        </ul>
      </p>
    </FunctionPageLayout>
  );
};

export default Metodyfinansowania;
